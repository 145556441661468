import { Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import DeleteModal from '../../../Components/Modal/DeleteModal';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Service from '../../../../../Service';
import AddEditDebtPayment from './addEditDebtPayment';

const DebtPayment = ({ setUpdateList }) => {
    moment().locale("tr");
    const [curentPage, setCurentPage] = useState(1);
    const [update, setUpdate] = useState(false);
    const [data, setData] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const { id } = useParams();
    const [isModal, setIsModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);


    useEffect(() => {
        const service = new Service();
        service.DebtPaymentService
            .getPaymentsForUser(`userId=${id}&page=${curentPage}&pagesize=50`)
            .then((res) => {
                setData(res);
                setUpdateList((prev) => !prev);
            });
    }, [curentPage, id, update, setUpdateList]);

    const onDeleteWellUser = (id) => {
        setSelectedId(id);
        setIsDeleteModal((prev) => !prev);
    };

    const onUpdateWellUser = (id) => {
        setSelectedId(id);
        setIsModal((prev) => !prev);
    };


    const columns = [
        {
            title: "ödeme Tarih",
            dataIndex: "paymentDate",
            key: "paymentDate",
            render: (text, record) => {
                return <span>{moment(record.paymentDate).format("LLL")}</span>;
            },
        },
        {
            title: "Tutar",
            dataIndex: "amount",
            key: "amount",
            render: (text, record) => {
                return <span>{text + "₺"}</span>;
            },
        },
        {
            title: "İslemler",
            align: "center",
            render: (text, record) => {
                return {
                    children: (
                        <div className="flex flex-wrap">
                            <button
                                className="bg-red-500 hover:bg-red-700 font-bold py-2 px-4 mb-2 me-1 text-white rounded"
                                onClick={() => onDeleteWellUser(record.id)}
                            >
                                Sil
                            </button>
                            <button
                                className="bg-cyan-500 hover:bg-cyan-700 font-bold py-2 px-4 mb-2 rounded text-white"
                                onClick={() => onUpdateWellUser(record.id)}
                            >
                                Düzenle
                            </button>
                        </div>
                    ),
                };
            },
        },
    ];

    return (
        <div> <div className="flex flex-wrap my-6">
            <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded flex justify-center items-center"
                onClick={() => {
                    setIsModal((prev) => !prev);
                    setSelectedId(null);
                }}
            >
                <AiOutlinePlus /> Ödeme Ekle
            </button>
        </div>

            <p className="">Ödeme Listesi</p>
            <Table
                pagination={{
                    onChange: (pagination) => setCurentPage(pagination),
                    pageSize: 50,
                    total: data?.totalPages * 50,
                }}
                columns={columns}
                dataSource={data?.results}
                scroll={{ x: "100%" }}
            />
            <DeleteModal
                isShow={isDeleteModal}
                setIsShow={setIsDeleteModal}
                deleteUrl="api/deletePayment?paymentId="
                selectedId={selectedId}
                setUpdate={setUpdate}
            />
            <Modal
                title={selectedId != null ? "Ödeme Düzenle" : "Ödeme Ekle"}
                footer={null}
                open={isModal}
                destroyOnClose={true}
                onCancel={() => setIsModal((prev) => !prev)}
            >
                <AddEditDebtPayment
                    userId={id}
                    setUpdate={setUpdate}
                    setModal={setIsModal}
                    selectedId={selectedId}
                />
            </Modal></div>
    )
}
export default DebtPayment